@import '../assets/fonts/Roboto/Roboto.css';
@import '../assets/fonts/Inter/Inter.css';
@import './index.css';

.w-fit-content {
  width: fit-content;
}

.h-fit-content {
  height: fit-content;
}

.article-content {
  h1 {
    font-size: 46px;
    font-weight: bold;
  }
  h2 {
    font-size: 38px;
  }
  h3 {
    font-size: 20px;
  }
  h1,
  h2,
  h3 {
    margin: 10px 0;
  }
}
