.svg_checkmark {
  display: block;
  height: 10vw;
  width: 10vw;
  /* height: 40vw;
  width: 40vw;
  color: '#5E60CE'; SVG path use currentColor to inherit this */
}

.circle {
  stroke-dasharray: 76;
  stroke-dashoffset: 76;
  animation: draw 1s forwards;
}

.tick {
  stroke-dasharray: 18;
  stroke-dashoffset: 18;
  animation: draw 1s forwards 1s;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
