@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(../../assets/jobDetails/fonts/Inter.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter-variable';
  src: local('Inter-variable'), url(../../assets/jobDetails/fonts/InterVariable.ttf) format('opentype');
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-weight: 400;
  font-family: Inter;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  width: 100%;
  height: 100%;
  max-width: 1520px;
  margin: 0 auto;
  position: relative;
}

.home .section1 {
  /* background-image: url('../../assets/jobDetails/img/home1-mobile.png'); */
  background-image: url('../../assets/jobDetails/img/home1-mobile.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.home .section6 {
  background-image: url('../../assets/jobDetails/img/home1-1-mobile.png');
  height: calc(100vh - 80px);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.home .section3 {
  height: 100%;
  width: 100%;
  /* min-height: 546px; */
}

.menulist {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: white;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  display: block;
  padding: 18px;
}

.menulist .nav-menu {
  height: calc(100vh - 180px);
}

.nav-menu {
  width: 100vw;
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.job-details .section1 {
  background-image: url('../../assets/jobDetails/img/job1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.job-details .section1red {
  background-image: url('../../assets/jobDetails/img/job1red.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 476px) {
  .home .section1 {
    background-image: url('../../assets/jobDetails/img/home1-tablet.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .home .section6 {
    background-image: url('../../assets/jobDetails/img/home1-1-tablet.png');
    height: auto;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 1025px) {
  .home .section1 {
    /* height: calc(100vh - 80px); */
    background-image: url('../../assets/jobDetails/img/home1.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
  }

  .home .section6 {
    height: calc(100vh - 80px);
    background-image: url('../../assets/jobDetails/img/home1-1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}

.btn-animation {
  transition: 0.4s;
}

.half-ellipse {
  border-radius: 50% / 100% 100% 0 0;
}
