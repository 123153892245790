@font-face {
  font-family: 'Roboto-Black';
  src: local('Roboto'), url('Roboto-Black.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-BlackItalic';
  src: local('Roboto'), url('Roboto-BlackItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Bold';
  src: local('Roboto'), url('Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-BoldCondensed';
  src: local('Roboto'), url('Roboto-BoldCondensed.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-BoldCondensedItalic';
  src: local('Roboto'), url('Roboto-BoldCondensedItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-BoldItalic';
  src: local('Roboto'), url('Roboto-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Condensed';
  src: local('Roboto'), url('Roboto-Condensed.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-CondensedItalic';
  src: local('Roboto'), url('Roboto-CondensedItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Italic';
  src: local('Roboto'), url('Roboto-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Light';
  src: local('Roboto'), url('Roboto-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-LightItalic';
  src: local('Roboto'), url('Roboto-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('Roboto-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-MediumItalic';
  src: local('Roboto'), url('Roboto-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Regular';
  src: local('Roboto'), url('Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-Thin';
  src: local('Roboto'), url('Roboto-Thin.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto-ThinItalic';
  src: local('Roboto'), url('Roboto-ThinItalic.ttf') format('truetype');
}
