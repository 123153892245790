.title {
  font-family: 'Inter Semi Bold';
  font-weight: 'bold';
  font-size: '46px';
  line-height: '120%';
  align-items: 'center';
  color: '#0069BF';
}

.description {
  font-family: 'Inter Light BETA';
  font-weight: 300;
  font-size: '18px';
  line-height: '200%';
  text-align: 'center';
  color: '#0C416C';
  opacity: 0.7;
}
